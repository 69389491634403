import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { constantVariables } from '@shared/general/configs';
import { GeneralService } from '@shared/services/general.service';

export const authGuard: CanActivateFn = (route, state) => {
  const generalService = inject(GeneralService);

  if (localStorage.getItem(constantVariables.refreshToken)) {
    generalService.router.navigateByUrl('/');
    return false;
  }
  return true;
};
