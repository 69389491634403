import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideRouter,
  withComponentInputBinding,
  withNavigationErrorHandler,
  withViewTransitions,
} from '@angular/router';
import { graphqlProvider } from '@providers/graphql.provider';
import { handleNavigationError } from '@providers/route-error-handler.provider';
import { handelSentry } from '@providers/sentry.provider';
import { MyPaginatorIntl } from '@shared/general/pagination-internationalization';
import { authInterceptor } from '@shared/interceptors/auth.interceptor';
import { headerInterceptor } from '@shared/interceptors/header.interceptor';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideClientHydration(withEventReplay()),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withViewTransitions(),
      withNavigationErrorHandler(handleNavigationError),
    ),
    provideAnimationsAsync(),
    provideHttpClient(withFetch(), withInterceptors([authInterceptor, headerInterceptor])),
    MatIconRegistry,
    graphqlProvider,
    {
      provide: MatPaginatorIntl,
      useClass: MyPaginatorIntl,
    },
    ...handelSentry(),
  ],
};
