import { Routes } from '@angular/router';
import { accessGuard } from '@shared/guards/access.guard';
import { authGuard } from '@shared/guards/auth.guard';

const MainLayoutRoutes: Routes = [
  {
    path: '',
    canActivate: [accessGuard],
    loadComponent: () =>
      import('./layout/main-layout/main-layout.component').then((c) => c.MainLayoutComponent),
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/main-layout/main.routes').then((r) => r.routes),
      },
    ],
  },
];

const ProductLayoutRoutes: Routes = [
  {
    path: '',
    canActivate: [accessGuard],
    loadComponent: () =>
      import('./layout/product-layout/product-layout.component').then(
        (c) => c.ProductLayoutComponent,
      ),
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/product-layout/product.routes').then((r) => r.routes),
      },
    ],
  },
];

const AuthLayoutRoutes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./layout/authentication-layout/authentication-layout.component').then(
        (c) => c.AuthenticationLayoutComponent,
      ),
    children: [
      {
        path: '',
        title: 'Thumb Zone',
        loadChildren: () =>
          import('./pages/authentication-layout/auth.routes').then((r) => r.routes),
      },
    ],
  },
];

const ErrorLayoutRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./layout/error-layout/error-layout.component').then((c) => c.ErrorLayoutComponent),
    children: [
      {
        path: 'error/:statusCode',
        title: 'Thumb Zone | Error !',
        loadComponent: () =>
          import('./pages/error-layout/error.component').then((m) => m.ErrorComponent),
      },
    ],
  },
];

export const routes: Routes = [
  ...MainLayoutRoutes,
  ...ProductLayoutRoutes,
  ...AuthLayoutRoutes,
  ...ErrorLayoutRoutes,
  {
    path: '**',
    redirectTo: '/error/404',
  },
];
