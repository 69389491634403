{
  "name": "dashboard-panel",
  "version": "4.2.3",
  "scripts": {
    "prepare": "husky",
    "ng": "ng",
    "start": "ng serve --port 4300",
    "start:prod": "ng serve --port 4300 --configuration production",
    "serve:stage": "NG_APP_ENV=stage npm run start",
    "serve:production": "NG_APP_ENV=production npm run start",
    "build": "NG_APP_ENV=production ng build",
    "build:prod": "NG_APP_ENV=production ng build && npm run sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "generate:schema": "graphql-codegen",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org thumbzone --project dashboard ./dist && sentry-cli sourcemaps upload --org thumbzone --project dashboard ./dist",
    "release": "git add --all && npx git-cz && release-it"
  },
  "lint-staged": {
    "*.{js,jsx,ts,tsx,md,html,css,scss,json,yaml}": "prettier --write"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.3",
    "@angular/cdk": "19.1.1",
    "@angular/common": "^19.1.3",
    "@angular/compiler": "^19.1.3",
    "@angular/core": "^19.1.3",
    "@angular/forms": "^19.1.3",
    "@angular/material": "19.1.1",
    "@angular/platform-browser": "^19.1.3",
    "@angular/platform-browser-dynamic": "^19.1.3",
    "@angular/router": "^19.1.3",
    "@apollo/client": "^3.12.7",
    "@graphql-codegen/cli": "^5.0.3",
    "@graphql-codegen/typescript": "^4.1.2",
    "@graphql-codegen/typescript-apollo-angular": "^4.0.0",
    "@graphql-codegen/typescript-operations": "^4.4.0",
    "@sentry/angular": "^8.51.0",
    "@sentry/cli": "^2.41.1",
    "apexcharts": "^3.54.0",
    "apollo-angular": "8.0.0",
    "crypto-js": "^4.2.0",
    "graphql": "^16",
    "graphql-ws": "^6.0.2",
    "jwt-decode": "^4.0.0",
    "keen-slider": "^6.8.6",
    "konva": "^9.3.18",
    "ng-otp-input": "^2.0.6",
    "ngx-ui-tour-md-menu": "^14.0.1",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.4",
    "@angular/cli": "^19.1.4",
    "@angular/compiler-cli": "^19.1.3",
    "@ngx-env/builder": "19.0.4",
    "@types/crypto-js": "^4.2.2",
    "@types/jasmine": "~5.1.5",
    "auto-changelog": "^2.5.0",
    "git-cz": "^4.9.0",
    "husky": "^9.1.7",
    "jasmine-core": "~5.5.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.4.1",
    "prettier": "^3.4.2",
    "release-it": "^18.1.1",
    "typescript": "~5.7.3"
  }
}
